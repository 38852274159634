import React, { Component } from 'react'
    
class Sommerjobb extends Component {
    componentDidMount() {
        window.location.replace("https://candidate.hr-manager.net/ApplicationInit.aspx?cid=1889&ProjectId=146799&DepartmentId=21861&MediaId=4737");
    }

    render() {
        return <div />
    }
}

export default Sommerjobb
